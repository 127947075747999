import React from 'react';

const StateContext = React.createContext();

class StateProvider extends React.Component {
  state = {
    isDesktop: true,
    menuOpen: true,
    herodata: {
      amount: {
        cents: 0
      },
      target_cents: 450000,
      donation_url: 'https://donate.everydayhero.com/d/pYu-PJWqUUiTfP6c-4CELQ'
    }
  };

  setIsDesktopState = () => {
    this.setState({ isDesktop: window.innerWidth > 767 ? true : false });
    this.state.isDesktop
      ? this.setState({ menuOpen: this.state.isDesktop })
      : this.setState({ menuOpen: false });
  };

  componentDidMount() {
    this.setState({ isDesktop: window.innerWidth > 767 ? true : false });
    this.setState({ menuOpen: window.innerWidth > 767 ? true : false });
    window.addEventListener('resize', this.setIsDesktopState);

    fetch('/.netlify/functions/fetch-everydayhero-api').then(response =>
      response.json().then(data => {
        const herodata = data.pages[0];
        this.setState({ herodata });
      })
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setIsDesktopState);
  }

  render() {
    const { children } = this.props;
    return (
      <StateContext.Provider
        value={{
          ...this.state,
          handleMenuClick: () => {
            this.setState({ menuOpen: !this.state.menuOpen });
          },
          handleLinkClick: () => {
            this.state.isDesktop
              ? this.setState({ menuOpen: this.state.isDesktop })
              : this.setState({ menuOpen: false });
          },
          handleMenuKey: e => {
            e.which === 13 || e.which === 32
              ? this.setState({ menuOpen: !this.state.menuOpen })
              : e.preventDefault();
          }
        }}
      >
        {children}
      </StateContext.Provider>
    );
  }
}

export default StateContext;

export { StateProvider };
